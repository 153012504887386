<template>
  <div class="step_container">
      <a-form-model layout='horizontal'
      ref="person_second_form"
      :model="secondForm"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol">
    <h1 class="step_title">ACCOUNT BEHAVIOUR BASELINE 账户行为基准</h1>
    <div class="step_question_container">
      <h2 class="step_question_title">
       SOURCE OF FUNDS/WEALTH AND PURPOSE OF TRADE  资金来源和交易目的
      </h2>

        <a-form-model-item
        prop="source_of_funds_text"
        required label="Soure of Funds/Wealth 资金来源">
          <a-textarea
            placeholder=""
            v-model='secondForm.source_of_funds_text'
            @change='(e)=>{
              handleInput(e,"source_of_funds_text")
            }'
            :auto-size="{ minRows: 3, maxRows: 3 }"
    />
    <div class="textarea_desc">I.e.Personal savings in bank in Australia/New Zealand; sale of another property in Australia/China; inheritance; health products trading revenue; cosmetics importing and exporting revenue./Employment wages and salaries</div>
        </a-form-model-item>
        <a-form-model-item
        prop="purpose_of_transaction_text"
        required label="Purpose of Transaction 换汇目的">
          <a-textarea
      placeholder=""
      v-model='secondForm.purpose_of_transaction_text'
      @change='(e)=>{
        handleInput(e,"purpose_of_transaction_text")
      }'
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
    <div class="textarea_desc">I.e.Repatriate overseas salary back to Australia/New Zealand; purchasing property in Australia/New Zealand; paying for overseas purchases,mainly cosmetics; paying tuition and living cost in Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰; 在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品; 在澳大利亚/新西兰支付学费和生活费。</div>
        </a-form-model-item>
        <a-form-model-item
        prop="transaction_country_list"
        required label="List of countries normally dealing/trading with 请列出您产生交易/贸易的国家或地区">
          <a-textarea
      placeholder=""
      v-model='secondForm.transaction_country_list'
      @change='(e)=>{
        handleInput(e,"transaction_country_list")
      }'
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
        </a-form-model-item>
    </div>
    <div class="step_question_container">
      <h2 class="step_question_title">
       TRANSACTION AND PAYMENT 交易和付款
      </h2>
        <a-form-model-item
        prop="num_of_anual_transactions"
        required label="Approx. number of annual transactions 大约每年交易次数是多少">
          <a-input
          @change="(value)=>{handleInput(value,'num_of_anual_transactions')}"
          v-model='secondForm.num_of_anual_transactions' placeholder="">
          </a-input>
        </a-form-model-item>
        <a-form-model-item
        prop="frequency_of_transaction"
        required label="Approx. number & frequency of transactions 大约交易频率是多少">
          <div class="textarea_desc">I.e. 5 weekly / 3 monthly / 2 quarterly 例如：5次每周 / 3次每月 / 2次每季度</div>
          <a-input
          @change="(value)=>{handleInput(value,'frequency_of_transaction')}"
          v-model='secondForm.frequency_of_transaction' placeholder="">
          </a-input>
        </a-form-model-item>
        <a-form-model-item
        prop="total_volumn_of_trasaction_per_year"
        label="Approx. number tade volume (in NZD) 大约每年交易金额是多少（纽币）">
          <a-input
          @change="(e)=>{handleInput(e,'total_volumn_of_trasaction_per_year')}"
          v-model='secondForm.total_volumn_of_trasaction_per_year' />
        </a-form-model-item>
        <a-form-model-item
        prop="average_volume_of_per_transaction"
        label="Approx. average transaction volume (in NZD) 大约平均每笔交易金额是多少（纽币）">
          <a-input
          @change="(e)=>{handleInput(e,'average_volume_of_per_transaction')}"
          v-model='secondForm.average_volume_of_per_transaction'/>
        </a-form-model-item>
        <a-form-model-item
        prop="is_money_from_high_risk_country"
        label="Will you remit/receive any money directly to/from a high risk country or indirectly via another country? 您是直接向高风险国家汇出/接收任何资金还是通过其他国家间接汇款？">
          <a-radio-group name="is_money_from_high_risk_country"
          class="modify_antd_radio"
          @change="handleRadioSelect"
          v-model="secondForm.is_money_from_high_risk_country">
          <a-radio :value='true'>
            Yes 是
          </a-radio>
          <a-radio :value="false">
            No 否
          </a-radio>
        </a-radio-group>
          <a-form-model-item
          prop="money_from_high_risk_country_specific"
          v-if='is_money_from_high_risk_country == true'
          >
          <a-input
          @change="(value)=>{handleInput(value,'money_from_high_risk_country_specific')}"
          v-model='secondForm.money_from_high_risk_country_specific' placeholder="Please specific">
          </a-input>
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item
        prop="had_trade_with_high_rish_country"
        label="Do you trade with any high risk countries? (directly or via a third party) 您是否与任何高风险国家进行交易？ （直接或通过第三方）">
          <a-radio-group name="had_trade_with_high_rish_country"
          class="modify_antd_radio"
          @change="handleRadioSelect"
          v-model="secondForm.had_trade_with_high_rish_country">
          <a-radio :value='true'>
            Yes 是
          </a-radio>
          <a-radio :value="false">
            No 否
          </a-radio>
        </a-radio-group>
        <a-form-model-item
        prop="trade_with_high_rish_country_specific"
        v-if='had_trade_with_high_rish_country == true'
        >
        <a-input
          @change="(value)=>{handleInput(value,'trade_with_high_rish_country_specific')}"
          v-model='secondForm.trade_with_high_rish_country_specific' placeholder="Please specific">
          </a-input>
        </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item
        prop="is_pay_for_thrid_party"
        label="Do you make payments to third parties? 您向第三方付款吗？">
          <a-radio-group name="is_pay_for_thrid_party"
          class="modify_antd_radio"
          @change="handleRadioSelect"
          v-model="secondForm.is_pay_for_thrid_party">
          <a-radio :value='true'>
            Yes 是
          </a-radio>
          <a-radio :value="false">
            No 否
          </a-radio>
        </a-radio-group>
        <a-form-model-item
        prop="pay_for_thrid_party_specific"
        v-if='is_pay_for_thrid_party == true'
        >
        <a-input
          @change="(value)=>{handleInput(value,'pay_for_thrid_party_specific')}"
          v-model='secondForm.pay_for_thrid_party_specific' placeholder="Please specific">
          </a-input>
          </a-form-model-item>
        </a-form-model-item>
    </div>
    </a-form-model>
  </div>
</template>

<script>
// import { getTaxCountry } from '@/api/root'
import { mapActions, mapState } from 'vuex'

export default {
  created () {
  },
  methods: {
    handleRadioSelect (e) {
      const { name, value } = e.target
      this.ChangeFormAction({ content: name, value })
    },
    handleInput (e, content) {
      const { value } = e.target
      console.log(value)
      this.ChangeFormAction({ content, value })
    },
    handleSelect (value, content) {
      // console.log(value)
      this.ChangeFormAction({ content, value })
    },
    ...mapActions('personStepTwo', ['ChangeFormAction'])

  },
  data () {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.person_second_form.validate(valid => {
      if (valid) {
        next()
      } else {
        next()
      }
    })
  },
  computed: {
    secondForm () {
      return this.$store.state.personStepTwo
    },
    ...mapState('personStepTwo', ['is_money_from_high_risk_country', 'money_from_high_risk_country_specific', 'had_trade_with_high_rish_country', 'trade_with_high_rish_country_specific', 'is_pay_for_thrid_party', 'pay_for_thrid_party_specific', 'rules'])
  }
}
</script>

<style scoped>

.step_container {
  background: rgb(248, 248, 248);
}

.modify_antd_radio >>> .ant-radio-inner{
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-inner::after{
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.ant-form >>> label{
  font-size: 15px;
}
.textarea_desc{
  line-height: 20px;
  color:#777;
  margin-bottom: 10px;
}
</style>
